<template>
    <div class="mb-4 relative">
        <label :id="model + '-1'" for="input" class="block text-gray-500 text-xs text-left font-bold ml-1 -mb-8" >
            {{nameInpt}}
        </label>
        <input v-if="disabled === false" :id="model" type="text" v-model="value" @blur="activeLabel($event)" @focus="activeLabel($event)"  class="bg-gray-300 bg-opacity-25 appearance-none border-b-2 border-blue-600 w-full pt-3 mb-2 px-1 text-gray-600 leading-tight focus:border-orange-500 focus:outline-none mt-2" >
        <input v-else :id="model" disabled="disabled" type="text" v-model="value" @blur="activeLabel($event)" @focus="activeLabel($event)"  class="bg-gray-300 bg-opacity-25 appearance-none border-b-2 border-blue-600 w-full pt-3 mb-2 px-1 text-gray-600 leading-tight focus:border-orange-500 focus:outline-none mt-2" >
        
    </div>
</template>

<script>
    export default {
        name: 'FielText',
        mounted() {
            // console.log(this.default)
            if (this.defaults) {
                const el = document.querySelector(`#${this.model}-1`)
                el.classList.toggle('active')
                el.classList.toggle('active-text')
                this.value = this.defaults
            }
        },
        props: {
            nameInpt: {
                type: String,
                default: 'name default'
            },
            model: {
                type: String,
                default: 'name'
            },
            defaults: {},
            disabled:{
                type: Boolean,
                defaults:false,
            },
        },
        data() {
            return {
                value: '',
                field: '',
                vdefault: '',
            }
        },
        methods: {
            activeLabel(event){

                let val = event.target.value
                // console.log(val)
                const el = document.querySelector(`#${this.model}-1`)
                if( val === '') {
                    el.classList.toggle('active')
                }
                if( val === '') {
                    // el.classList.toggle('active')
                    el.classList.toggle('active-text')
                }
            },
        },
        watch: {
            value (value){
                this.$emit('input', value)
            },
            defaults (val){
                if (this.defaults !== '') {
                    const el = document.querySelector(`#${this.model}-1`)
                    el.classList.add('active')
                    el.classList.add('active-text')
                }
                this.value=this.defaults
            }
            
        },
    }
</script>

<style >

label{
    transition: all 0.3s ease-in-out;
}
label.active {
    -ms-transform: translate(1px,-19px); /* IE 9 */
    transform: translate(1px,-19px); /* Standard syntax */
}
label.active-text {
    color: #5c74fc;
}
</style>