<template>
	<div v-if="hasPermission">
		<keep-alive>
			<component v-bind:is="currentComponent"></component>
		</keep-alive>
		<!-- <AccTransactions /> -->
	</div>
</template>

<script>
// @ is an alias to /src

// import Loans from '@/components/content/content-loans/ContentLoans.vue'
import AccTransactions from '@/components/content/content-acc/AccTransactions.vue'
import { getPermissions } from '../components/util/liblist'

export default {
	name: 'AccountTransactions',
	components: {
		AccTransactions,
	},
	data() {
		return {
			currentComponent: 'AccTransactions',
			'hasPermission': false, 
		}
	},
	mounted() {
		if (getPermissions('accounting.entries.store')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
